@mixin core-menu-theme($theme) {
  $main: map-get($theme, main);
  $contrast: map-get($theme, contrast);

  .mat-toolbar {
    background-color: $main !important;

    .mat-icon {
      color: $contrast;
    }

    span {
      color: $contrast;
    }
  }

  .app-sidenav {
    background-color: $main !important;

    .mat-icon {
      color: $contrast;
    }

    .mat-expansion-panel {
      background-color: $main !important;
    }

    .mat-expansion-indicator::after {
      color: $contrast !important;
    }

    span {
      color: $contrast;
    }
  }

  .notification-sidenav {
  }

  .user-profile {
    color: $contrast;
  }

  .user-menu {
    .mat-icon {
      color: $main !important;
    }
  }
}