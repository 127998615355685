@use '@angular/material' as mat;

@import './themes/green.scss';
@import './themes/blue.scss';
@import './themes/dark.scss';
@import './themes/light.scss';
@import './custom.theme.scss';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

.custom-theme {
  @include mat.core-theme($light-theme);
  @include mat.button-theme($light-theme);
  @include mat.radio-theme($light-theme);
  @include mat.slide-toggle-theme($light-theme);
  @include mat.icon-theme($light-theme);
  @include mat.form-field-theme($light-theme);
  @include mat.input-theme($light-theme);
  @include mat.stepper-theme($light-theme);
  @include mat.checkbox-theme($light-theme);
  @include mat.progress-spinner-theme($light-theme);
  @include mat.datepicker-theme($light-theme);
  @include mat.expansion-theme($light-theme);
  @include mat.chips-theme($light-theme);
  // @include mat.all-component-themes($light-theme);
}

// :root {
//   @include mat.all-component-colors($green-theme);
//   @include custom-theme($green-theme-colors);
// }

.green-theme {
  @include mat.all-component-colors($green-theme);
  @include custom-theme($green-theme-colors);
}

.blue-theme {
  @include mat.all-component-colors($blue-theme);
  @include custom-theme($blue-theme-colors);
}