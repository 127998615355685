@mixin awbs-tracking-theme($theme) {
  $main: map-get($theme, main);
  $contrast: map-get($theme, contrast);

  app-awbs-tracking {
    mat-card-title {
      background-color: $main;
      color: $contrast;
    }

    .awb-number {
      color: $main;
    }

    .created-by {
      color: $main;
    }
  }
}