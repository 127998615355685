@mixin notifications-theme($theme) {
  $main: map-get($theme, main);
  $contrast: map-get($theme, contrast);

  app-notifications {
    .notification-header {
      color: $contrast;
      background-color: $main;
    }

    .notification-title {
      color: $contrast;
    }

    .awb-number {
      color: $main;
    }

    .close-btn {
      color: $contrast;
    }
  }
}