.page-title {
  margin: 30px 0;
  font-size: 36px;
}

.page-content {
  display: flex;
  justify-content: center;
}

.page-header {
  font-size: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 16px;
}

mat-paginator {
  background-color: transparent !important;
}

.material-icons {
  vertical-align: middle;
}

.form-container {
  .mat-mdc-card {
    padding: 20px;
  }
}

.clickable {
  cursor: pointer;
}