.table thead th {
  border-top-width: 2px;
  border-top-style: solid;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  font-size: 16px;
}

.table td,
.table th {
  vertical-align: middle;
  padding: 6px;
  white-space: nowrap;
  background-color: #fafafa;
}

.table-responsive {
  padding: 20px 0;
}

.table-row-actions {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;

  .disabled {
    pointer-events: none;
    filter: opacity(0.5);
  }

  .mat-icon,
  .mat-icon-button {
    cursor: pointer;
  }
}

.table-empty {
  font-size: 14px;
}

.table-actions-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px 0;

  button {
    min-width: 5rem;
  }
}

.table-bottom-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.buttons-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;

  button {
    min-width: 5rem;
  }
}