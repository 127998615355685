@mixin reports-awbs-theme($theme) {
  $main: map-get($theme, main);
  $contrast: map-get($theme, contrast);

  app-reports-awbs {

    .price-row {
      background-color: $main;

      span {
        color: $contrast;
      }
    }
  }
}