@mixin navbar-theme($theme) {
  $main: map-get($theme, main);
  $contrast: map-get($theme, contrast);

  app-navbar {
    .navbar {
      background: linear-gradient(to right, $main 0%, $main 50%, $main 51%, $main 100%);
    }

    .dropdown-menu{
      background-color: $main;

      a {
        color: $contrast;
      }

      a:hover {
        background-color: lighten($main, 3%);
      }
    }
  }
}