@mixin footer-theme($theme) {
  $main: map-get($theme, main);

  app-footer {
    .contact-item {
      a {
        color: $main;
      }
    }

    .schedule-item {
      color: $main;
    }
  }
}