/* You can add global styles to this file, and also import other style files */
/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
// @media screen and (min-width: 601px) {
//   h1 {
//     font-size:1rem;
//   }
// }
// /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
// @media screen and (max-width: 600px) {
//   .carousel-caption {
//     font-size: 8px;
//   }
// }
// @import 'customize-bootstrap.scss';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';
@import 'style/tables.scss'; 
@import 'style/theme.scss'; 
@import 'style/custom.style.scss';

/*
Extra small devices (portrait phones, less than 544px)
No media query since this is the default in Bootstrap because it is "mobile first"
*/
/*1rem = 16px*/

html {
  font-size: 16px;
}

h1 {
  font-size: 1rem;
}

* {
  box-sizing: border-box;
}

*:after,
*:before {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
}
/*1rem = 16px*/
/*
####################################################
M E D I A  Q U E R I E S
####################################################
*/
/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints
*/
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 0.6rem;
  }

  h4 {
    font-size: 0.8rem;
  }

  h5 {
    font-size: 0.6rem;
  }

  h6 {
    font-size: 0.4rem;
  }
}
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 0.6rem;
  }

  h4 {
    font-size: 0.8rem;
  }

  h5 {
    font-size: 0.6rem;
  }

  h6 {
    font-size: 0.4rem;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2.1rem;
  }

  h3 {
    font-size: 1.9rem;
  }

  h4 {
    font-size: 1.7rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  h6 {
    font-size: 1.3rem;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2.3rem;
  }

  h4 {
    font-size: 2.1rem;
  }

  h5 {
    font-size: 1.9rem;
  }

  h6 {
    font-size: 1.7rem;
  }
}
// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap
// Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { ... }
//
// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { ... }
//
// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }
//
// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }

.page-container {
  padding: 20px;
}

.breadcrumb-item {
  a {
    color: var(--primary-color);
  }
}

.error {
  color: red;
}

.amount-input {
  width: 10rem;
}

.col-amount {
  width: 1px;
  text-align: right;
  white-space: nowrap;
}

.min-width {
  width: 1px;
}

.col-index {
  width: 1px;
}

.col-actions {
  width: 1px;
}

.error-control {
  color: red !important;
}

.error-field,
.error-field:focus {
  border: 2px solid red;
  box-shadow: 0 0 0 0.2rem rgba(193, 66, 66,0.25);
}

.btn {
  white-space: nowrap;
}

.main-badge {
  color: #fff;
  background-color: var(--primary-color);
}

.form-panel {
  color: var(--primary-color);
  margin-bottom: 1rem;

  fieldset {
    border: 2px solid var(--primary-color);
    padding: 5px;
  }

  legend {
    width: auto;
    border-bottom: none;
    padding: 0 5px;
  }
}

.main-button {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.main-button:hover {
  color: var(--secondary-color);
}

.main-outline-button {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.main-outline-button:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.link-button {
  color: var(--primary-color);
  padding: 0;
}

.badge {
  overflow: auto;
}

body,
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.form-actions {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  flex-wrap: wrap;

  button {
    margin: 3px;
  }
}