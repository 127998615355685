@mixin awb-tracking-theme($theme) {
  $main: map-get($theme, main);
  $contrast: map-get($theme, contrast);

  app-awb-tracking {
    mat-card-header {
      background-color: $main;
      color: $contrast;
    }
  }
}