@import '../app/app.module.theme.scss';

@mixin custom-theme($theme) {
  $main: map-get($theme, main);

  .page-title {
    color: $main;
  }

  .page-header {
    color: $main;
  }

  .alert {
    background-color: lighten($main, 10%);
    color: #ffffff;
  }

  .table thead th {
    border-color: $main;
    color: $main;
  }

  .table-row-actions {
    a {
      color: $main;
    }

    .mat-icon, .mat-icon-button {
      color: $main;
    }
  }

  .table-empty {
    color: $main;
  }

  @include app-module-theme($theme);
}